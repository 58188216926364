
.home-page{
max-width: 60%;
margin: 0 auto;
}

.app-bar{
max-width: 60%;
margin: 0 auto;
background-color: var(--primary);
color: #fff;
height: 60px;
width: 100%;
display: flex;
z-index: 100;
position: fixed;
top: 0;
left: 0;
right: 0;
align-items: center;
justify-content: space-between;
box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);

}
.app-bar-title{
font-size: 1.5rem;
font-weight: 500;
padding-left: 10px;
}
.app-bar-menu{
display: flex;
align-items: center;
}
.app-bar-menu .menu-item{
padding: 0 10px;
cursor: pointer;
}
.small-menu{
display: none;
}
.menu-item-icon{
    padding-right: 10px;
}
.menu-item{
    display: flex;
    align-items: center;
}
.small-menu-icon{
    padding-right: 10px;
}
/* if small screen use 100% width */ 
@media (max-width: 768px) {
.home-page{
max-width: 100%;
}
.app-bar{
max-width: 100%;
}
.app-bar-title{
font-size: 1.2rem;
}
.menu-item-text{
display: none;
}

}