
.first-sheet{
    height: 50%;
    width: 60%;
    background-color: white;
    /* //stack it on top of whats below */
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    margin: 0 auto;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    align-items: flex-start;
    text-align: left;

}
.first-sheet .location-text{
    color: var(--text-grey);
    font-size: .9rem;
}
.divider{
    width: 100%;
    height: 1px;
    background-color: #ededed;
    margin: 10px 0;
}
.welcome-box{
    padding-bottom: 20px;
}

/* if small screen, use 100% width  */
@media (max-width: 768px) {
    .first-sheet{
        width: 100%;
    }
}
