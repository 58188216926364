:root {
  --primary: #34C759;
  --primary-light: #4dabf5;
  --primary-dark: #005cb2;
  --secondary: #fbbc05;
  --text-grey: #666;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
   
}
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.splash_screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.onboarding_item {
  margin-bottom: 20px;
}

.splash_screen p{
  padding-left: 40px;
  padding-right: 40px;
  height: 30px;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-bottom: 40px;

}

.splash_screen button{
  height: 40px;
  background: var(--primary);
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding-left: 40px;
  padding-right: 40px;
  margin-right: 10px;
}
.splash_screen img{
  height: 600px;
  width: 70%;
}
.slide {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.slide:not(:first-child) {
  opacity: 0;
}

.indicator {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #bbb;
  margin: 0 5px;
  cursor: pointer;
}

.dot.active {
  background-color: var(--primary);
}

.user_type {
  background-color: var(--primary);
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  
}
.user_type .title {
  color: white;
  font-size: 24px;
  font-weight: bold;
  margin-top: 20px;
  
}
.user_type .subtitle {
  color: white;
  font-size: 16px;
  margin-bottom: 20px;
}
.user_type_cards {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
}
.user_type_cards .user_type_card {
  background-color: white;
  border-radius: 5px;
  padding: 20px;
  width: 200px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 10px;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;
}
.user_type_cards .user_type_card .title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}
.user_type_cards .user_type_card .type {
  font-size: 24px;
  font-weight: bold;
}
.user_type_cards .user_type_card .description {
  font-size: 16px;
  margin-bottom: 20px;
  color: #666;
}

.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border: 1px solid #cccccc;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 8px;
  z-index: 1000;
}

.dialog p {
  margin-bottom: 10px;
}

.dialog button {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  padding: 10px 50px;
  cursor: pointer;
}

.dialog_content {
  margin-bottom: 5px;
}
.dialog_title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}
.dialog button:hover {
  background-color: #0056b3;
}
.dialog button:hover {
  background-color: #0056b3;
}
.dialog_text {
  font-size: 16px;
  margin-bottom: 10px;
}


.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid var(--primary);
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.shimmer-effect {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.shimmer-effect::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(to right, transparent 0%, #f0f0f0 50%, transparent 100%);
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
      transform: translateX(-100%);
  }
  100% {
      transform: translateX(100%);
  }
}

.shimmer-effect-content {
  padding: 16px;
}

.shimmer-effect-header {
  height: 40px;
  margin-bottom: 16px;
  background-color: #f0f0f0;
}

.shimmer-effect-body {
  display: flex;
  flex-direction: column;
}

.shimmer-effect-body-text {
  height: 20px;
  margin-bottom: 8px;
  background-color: #f0f0f0;
}