
.summary-and-payment-page{
    max-width: 60%;
    margin: 0 auto;
    padding: 20px;
}

.desired-means-of-transport-header{
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
}
.desired-means-of-transport-header-text .header-text{
    font-size: 1.2rem;
    font-weight: 600;
}
.desired-means-of-transport-header-text .description{
    font-size: .9rem;
    font-weight: 400;
    color: #6c757d;
}
.distance-and-duration .distance{
    font-size: .9rem;
    font-weight: 600;
}
.distance-and-duration .duration{
    font-size: .9rem;
    font-weight: 600;
}
.distance-and-duration span{
    margin-right: 10px;
    color: var(--primary);
}
.desired-means-of-transport-body{
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
}
.desired-means-of-transport-body-vehicles{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}
.desired-means-of-transport-body-vehicles .vehicle{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 150px;
    width: 150px;
    border-radius: 50%;
    background-color: #f8f9fa;
    cursor: pointer;
    transition: all .3s ease;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.desired-means-of-transport-body-vehicles .vehicle.selected {
    background-color: var(--primary);
    color: #fff;
  }
  .desired-means-of-transport-body-vehicles .vehicle.selected img{
    filter: invert(1);
  }
.desired-means-of-transport-body-vehicles .vehicle:hover{
    background-color: #e9ecef;
}
.desired-means-of-transport-body-vehicles .vehicle img{
    width: 50px;
    height: 50px;
}
.desired-means-of-transport-body-vehicles .vehicle p{
    font-size: .9rem;
    font-weight: 600;
    margin-top: 10px;
}
.desired-means-of-transport-body-summary{
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
    background-color: #f8f9fa;
    padding-top: 20px;
    padding-left: 10px;
}
.desired-means-of-transport-body-summary .summary-text{
    font-size: .9rem;
    font-weight: 600;
    margin-bottom: 20px;
}
.summary-title-and-value{
    display: flex;
    justify-content: space-between;
    align-items: center;
  
}
.summary-title-and-value .title{
    font-size: .9rem;
    font-weight: 600;
}
.summary-title-and-value .value{
    font-size: .9rem;
    font-weight: 600;
    width: 50%;
    text-align: right;
    padding-right: 10px;
    padding-bottom: 20px;
}
.coupon{
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
}
.coupon_text_header{
    font-size: 1.2rem;
    font-weight: 600;
}
.coupon_text_description{
    font-size: .9rem;
    font-weight: 400;
    color: #6c757d;
}
.coupon_code_box{
    display: flex;
    flex-direction: row;
    padding-top: 20px;
}
.coupon_code_box button{
    height: 50px;
    margin-left: 10px;
    background-color: var(--primary);
    border: none;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 10px;
}

.order-success-message-container{
    max-width: 60%;
    margin: 0 auto;
    padding: 20px;
}
    

@media (max-width: 768px) {
    .summary-and-payment-page{
        max-width: 100%;
    }
    .order-success-message-container{
        max-width: 100%;
    }
}

/* oeoBP793jHY8etmA3yvTC0yRF1L2 */