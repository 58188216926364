
.send-package-detail-page {
  max-width: 60%;
  margin: 0 auto;
  padding: 20px;
}
.send-package-image-box
{
    height: 300px;
    width: 100%;
    background-color: var(--primary);
    border-radius: 20px;
}
.send-package-image-box img
{

    justify-content: center;
    align-items: center;
    display: flex;
    height: 200px;
    width: 200px;
    margin: 0 auto;
    transform: translateY(25%);
}
.send-package-detail-box .title_and_value
{
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    padding: 10px;
    flex-direction: column;
    text-align: left;
}
.send-package-detail-box .title_and_value .title
{
    font-size: 1rem;
    font-weight: 600;
}
.send-package-detail-box .title_and_value input
{ 
    border-radius: 6px;
    width: 98%;
    margin: 10px 0;
}
.place-predictions{
    
    background-color: white;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.place-predictions .place-prediction {
    padding: 10px;
    border-bottom: 1px solid var(--primary);
    cursor: pointer;
    font-size: .9rem;
}

.place-predictions .place-prediction:last-child {
    border-bottom: none;
}
.place-prediction-icon-and-text {
    display: flex;
    align-items: center;
    height: 25px;
}
.place-prediction-text {
    margin-left: 10px;
}
/* 
    if small screen use 100% width */
@media (max-width: 768px) {
  .send-package-detail-page {
    max-width: 100%;
  }
}
