
.login_page{
    max-width: 600px;
    margin: 0 auto;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    padding-top: 50px;
}


.login_page img{
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
}

.login_title{
    text-align: center;
    font-size: 1.4rem;
    margin: 20px 0;
    color: var(--primary);
    font-weight: 600;
}
.login_input{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.login_input label{
    font-size: .9rem;
    color: var(--primary);
    font-weight: 600;
    display: none;
    
}

.login_form{
    padding: 20px; 
    background-color: #fff;
    border-radius: 0 0 10px 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}


input{
    width: 100%;
    padding: 10px;
    margin: 5px 0 20px 0px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    margin-bottom: 20px;
    height: 40px;
    transition: .3s;
    font-size: .9rem;
}
select{
    width: 100%;
    padding: 10px;
    margin: 5px 0 20px 0px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    margin-bottom: 20px;
    height: 40px;
    transition: .3s;
    font-size: .9rem;
}
.error_message{
    color: red;
    font-size: .8rem;
    margin-bottom: 10px;
    transition: .3s;
    animation: error 1s;
}

.forgot_password{
    text-align: right;
    font-size: .8rem;
    color: var(--primary);
    font-weight: 600;
    cursor: pointer;
    transition: .3s;
}
.forgot_password:hover{
    color: var(--primary-dark);
}

.signup_link{
    text-align: center;
    font-size: .8rem;
    font-weight: 600;
    cursor: pointer;
    transition: .3s;
}
.signup_link span{
    color: var(--primary);
}

.terms_and_conditions{
    text-align: center;
    font-size: .8rem;
    color: #666;
    margin-top: 20px;
}