.my-trips-page{
    max-width: 60%;
    margin: 0 auto;
    border: 1px solid #f1f1f1;
    padding: 20px;
}

.my-trips-header{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    align-items: center;
    justify-self: center;
}
.my-trips-header-icon{
    padding-right: 10px;
    padding-left: 10px;
}
.my-trips-header p{
    font-size: 1.5rem;
    font-weight: 500;
}
.my-trips-header-icon{
    padding-right: 10px;
    font-size: 1.5rem;
}
.my-trips-chips{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
}
.chip{
    background-color: #f1f1f1;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 5px;
    animation: fadeIn 0.5s;
    cursor: pointer;
}
.chip.selected{
    background-color: var(--primary);
    color: #fff;
}
.chip.selected:hover{
    background-color: var(--primary);
    color: #fff;
}

.trip{
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 20px;
    border: 1px solid #f1f1f1;
    border-radius: 5px;
    animation: fadeIn 0.5s;
    cursor: pointer;
    justify-content: space-between;
    margin-bottom: 20px;   
}
.trip-title_and_time{
    display: flex;
    flex-direction: column;
    gap: 3px;
    align-items: last baseline;
}
.trip-title_and_time .collection{
    font-size: 1.0rem;
    font-weight: 500;
}
.trip-title_and_time .time{
    font-size: 0.9rem;
    color: #666;
}
.trip-status .status{
    font-size: 1.0rem;
    font-weight: 500;
    width: 100px;
    text-align: center;
    padding: 5px;
    height: 30px;
    border-radius: 5px;
    background-color: var(--primary);
    color: #fff;
    align-items: center;
    justify-content: center;
    display: flex;
}
.app-download-alert {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
    flex-direction: column;
    /* stack on top other content */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 500px;
    margin: auto;
    height: 200px;
    z-index: 999; /* ensure it's above other content */
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.5s;
}
.app-download-alert .title{
    font-size: 1.5rem;
    font-weight: 500;
}
.app-download-alert .description{
    font-size: 1.0rem;
    color: #666;
}
.app-download-alert .buttons{
    display: flex;
    gap: 10px;
    margin-top: 20px;
}
.app-download-alert .buttons .button{
    padding: 10px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 1.0rem;
    font-weight: 500;
    width: 170px;
    background-color: var(--primary);
    color: #fff;
}
.app-download-alert .buttons .button:hover{
    background-color: var(--primary);
    color: #fff;
}
.app-download-alert .close{
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 1.0rem;
    color: #f90404;
}


@media (max-width: 768px){
    .my-trips-page{
        max-width: 90%;
    }

    .app-download-alert{
        width: 90%;
    }

}
