.first-sheet-content {
  padding: 20px;
  margin-bottom: 20px;
}

.welcome-box {
justify-content: space-between;
 display: flex;
 align-items: center;
}

.welcome-text{
    display: flex;
    flex-direction: column;
    padding: 0px;
    align-items: flex-start;
    margin-bottom: 0;
}
.welcome-text .header{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0 !important;
}
.welcome-text .body{
    font-size: 14px;
    margin-top: 2px;
    color: var(--text-grey);
}

.how-to-video{
    width: 50px;
    height: 50px;
    background-color: var(--primary);
    border-radius: 10%;
    display: flex;
    cursor: pointer;
}
.how-to-video .play_icon{
    margin: auto;
    color: white;
    font-size: 30px;
}

.send-package-box{
    width: 97%;
   
    margin-top: 20px;
    box-shadow: 0px 0px 5px 0px #42424240;
   
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    padding-left: 10px;
    padding-bottom: 5px;
}

.send-package-box-header{
   display: flex;
   flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0;
    padding-top: 10px;
    padding-bottom: 10px;
}
.send-package-box-header .title{
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 0;
}
.send-package-box-header .subtitle{
    font-size: 13px;
    margin-top: 2px;
    color: var(--text-grey);
}
.send-package-box-body{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    padding-top: 10px;
    padding-bottom: 20px;
    padding-right: 20px;
}
.package-box-item{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0;
    padding-top: 10px;
    width: 90px;
    cursor: pointer;
}
.package-box-circle{
   height: 90px;
   width: 90px;
    background-color: var(--primary);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 5px 0px #42424240;
}
.package-box-item .title{
    align-self: center;
    font-size: 15px;
    font-weight: 600;
    padding-top: 6px;
}
.package-box-circle .package-box-image{
    width: 40px;
    height: 40px;
}

@media (max-width: 768px) {
    .send-package-box{
        width: 100%;
    }
    .package-box-item{
        width: 70px;
    }
    .send-package-box-body{
        padding-right: 10px;
    }
    .package-box-circle{
        height: 70px;
        width: 70px;
    }
    .package-box-item .title{
        font-size: 13px;
    }
    .package-box-circle .package-box-image{
        width: 30px;
        height: 30px;
    }
    
}