.buy-from-item {
    height: 90px;
    width: 90px;
}
.buy-from-item img {
  height: 100%;
  width: 100%;
}
.buy-from-item:hover {
  cursor: pointer;
  transform: scale(1.1);
}
.buy-from-item .title {
  font-size: .9rem;
  text-align: center;
  margin-top: 5px;
}
.send-package-box-body-buy-from{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
}